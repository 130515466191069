import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      tabLeftNav: (state) => state?.layout?.tabLeftNav,
    }),
  },

  methods: {
    mobileLeftBar() {
      this.$store.commit('layout/SET_TAB_LEFT_BAR')
    },
  },
}

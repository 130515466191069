<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="CTRL_PNL"
      tooltip-title="CTRL_PNL"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div
      class="grid 'xl:grid-cols-4' lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 sm:gap-7 mt-3 sm:mt-8"
    >
      <!-- Features Settings -->
      <div
        class="h-24 p-5 bg-bg-color-white col-span-1 rounded-lg grid grid-cols-2 gap-4 cursor-pointer"
        @click="redirectTo('fee-settings', '002')"
      >
        <div
          class="col-span-2 flex ltr:flex-row rtl:flex-row-reverse justify-around md:justify-between"
        >
          <div class="flex ltr:flex-1">
            <img class="" src="@assets/images/admin/campus.svg" alt="student" />
          </div>
          <div class="flex justify-end gap-2.5 flex-col">
            <div class="inline-flex gap-2 text-2xl rtl:flex-row-reverse">
              <span v-i18n="dashboard" class="ml-auto sm:ml-0">Settings</span>
            </div>
            <div class="flex ltr:justify-end">
              <p class="text-sm text-primary-purple-600 font-extrabold">Features Settings</p>
            </div>
          </div>
        </div>
      </div>
      <!-- System Settings -->
      <div
        class="h-24 p-5 bg-bg-color-white col-span-1 rounded-lg grid grid-cols-2 gap-4 cursor-pointer"
        @click="redirectTo('general-settings', '3')"
      >
        <div
          class="col-span-2 flex ltr:flex-row rtl:flex-row-reverse justify-around md:justify-between"
        >
          <div class="flex ltr:flex-1">
            <img class="" src="@assets/images/admin/campus.svg" alt="student" />
          </div>
          <div class="flex justify-end gap-2.5 flex-col">
            <div class="inline-flex gap-2 text-2xl rtl:flex-row-reverse">
              <span v-i18n="dashboard" class="ml-auto sm:ml-0">Settings</span>
            </div>
            <div class="flex ltr:justify-end">
              <p class="text-sm text-primary-purple-600 font-extrabold">System Settings</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Notification Settings -->
      <div
        class="h-24 p-5 bg-bg-color-white col-span-1 rounded-lg grid grid-cols-2 gap-4 cursor-pointer"
        @click="redirectTo('fee-notification-settings', '4')"
      >
        <div
          class="col-span-2 flex ltr:flex-row rtl:flex-row-reverse justify-around md:justify-between"
        >
          <div class="flex ltr:flex-1">
            <img class="" src="@assets/images/admin/campus.svg" alt="student" />
          </div>
          <div class="flex justify-end gap-2.5 flex-col">
            <div class="inline-flex gap-2 text-2xl rtl:flex-row-reverse">
              <span v-i18n="dashboard" class="ml-auto sm:ml-0">Settings</span>
            </div>
            <div class="flex ltr:justify-end">
              <p class="text-sm text-primary-purple-600 font-extrabold">
                Notification Trigger Settings
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import layoutMixin from '@src/mixins/layout-mixin.js'
export default {
  name: 'ControlPanelDashboard',
  components: {
    TitleWrapper,
  },
  mixins: [layoutMixin],
  data() {
    return {
      dashboard: 'dashboard',
    }
  },
  methods: {
    redirectTo(name, id) {
      this.$store.commit('layout/SET_ITEM_STATE', { id })
      this.$router?.push({ name })
      this.mobileLeftBar()
    },
  },
}
</script>
